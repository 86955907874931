import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSpeechSynthesis } from 'react-speech-kit';
import { AiFillSound } from "react-icons/ai";
import BeatLoader from "react-spinners/BeatLoader";
import Carousel from 'react-multi-carousel';
import Modal from 'react-modal';
import 'react-multi-carousel/lib/styles.css';
import { Rating, RoundedStar } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';

const serverUrl = (process.env.REACT_APP_API_URL) ? process.env.REACT_APP_API_URL : 'http://127.0.0.1:5000';

function ChatMessage({ message, previousMessage, language, showFeedback }) {
  const [loading, setLoading] = useState(message.text.length === 0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const sourceDataExists = message?.sourceData && message.sourceData.length > 0;
  let sourceDatas = (!sourceDataExists) ? [] : message.sourceData;
  const seen = new Set();
  const filteredDatas = sourceDatas.filter(item => {
    const key = `${item.page_num}-${item.pdf_name}`;
    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  React.useEffect(() => {
    setLoading(message.text.length === 0);
  }, [message]);
  const content = message.isBot ? (
    <>
      {!loading && <ReactMarkdown children={message.text} style={{ textAlign: 'justify' }} />}
      <BeatLoader
        color={'#ccc'}
        loading={loading}
        cssOverride={{
          margin: "2rem 0rem 0rem 1rem",
        }}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </>

  ) : (
    message.text
  );

  const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis({
  });

  const [showSourceData, setShowSourceData] = useState(false);
  //onst language = detectLanguage(message.text);
  const debug = true;

  const firstIfEnglish = (a, b) => language === 'English' ? a : b
  const firstIfBot = (a, b) => message.isBot ? a : b

  return (
    <div dir={firstIfEnglish('ltr', 'rtl')} className={`chat-message ${firstIfBot('bot-message', 'user-message')}`} style={{
      position: 'relative',
      paddingRight: firstIfEnglish('30px', '70px'),
      paddingLeft: firstIfEnglish('70px', '30px'),
      marginTop: '1.5rem',
      textAlign: firstIfEnglish('justify', 'right'),
    }}>
      {/* Chat Icon */}
      <div style={{
        position: 'absolute',
        right: firstIfEnglish('', '10px'), left: firstIfEnglish('10px', ''),
        bottom: firstIfBot('', '5px'), top: firstIfBot('20px', '')
      }}>
        <img src={firstIfBot('llama.png', 'user.png')} alt={firstIfBot('ChatBot', 'User')} style={{ width: '40px', height: '40px', borderRadius: '30px', position: 'relative' }} />
        <AiFillSound style={{ backgroundColor: '#4cd137', borderRadius: '10px', padding: '2px', color: 'white', position: 'absolute', bottom: '5px', right: '-3px', cursor: 'pointer' }}
          onClick={() => speak({ text: message.text, voice: (navigator.userAgent.includes('Edg')) ? voices[168] : voices[83] })} />
      </div>
      {/* Message */}
      {content}

      {/* Sources and Extra Sources Button  */}
      {sourceDataExists && <button
        style={{ borderRadius: '30px', backgroundColor: 'black', marginBottom: '1.3rem' }}
        onClick={() => setShowSourceData(!showSourceData)}
      >{(language == "English") ?
        (!showSourceData) ? "Show Sources" : "Hide Sources"
        :
        (!showSourceData) ? "عرض المصادر" : "إخفاء المصادر"
        }</button>}
      {sourceDataExists && debug && <button
        style={{ borderRadius: '30px', backgroundColor: 'black', marginBottom: '1.3rem', marginRight: '0.5rem' }}
        onClick={() => setIsOpen(true)}>...</button>}

      {/* Dropdown for Sources */}
      {sourceDataExists && showSourceData &&
        <SourcesCarousel sourceDataExists={sourceDataExists} showSourceData={showSourceData} filteredDatas={filteredDatas} responsive={responsive} serverUrl={serverUrl} />
      }
      {/* Modal for Extra Sources (i.e., more in depth sources) */}
      <ExtraSourcesModal sourceDatas={sourceDatas} language={language} serverUrl={serverUrl} setIsOpen={setIsOpen} modalIsOpen={modalIsOpen} responsive={responsive} />

      {/* Feefback Form */}
      {message.isBot && showFeedback && !submitted && (
        <FeedbackForm message={message} previousMessage={previousMessage} language={language} showFeedback={showFeedback}
          rating={rating} setRating={setRating} comment={comment} setComment={setComment} submitted={submitted} setSubmitted={setSubmitted}
          firstIfEnglish={firstIfEnglish} serverUrl={serverUrl}
        />
      )}
    </div >
  );
}

const SourcesCarousel = ({ sourceDataExists, showSourceData, filteredDatas, responsive, serverUrl }) => (
  <Carousel
    responsive={responsive}
  >
    {sourceDataExists && showSourceData && (
      // loop through sources
      filteredDatas.map((sourceData, index) => (
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '2rem 0rem' }}>
            {sourceData.pdf_name.startsWith("http") && <a href={sourceData.pdf_name + "#:~:text=" + sourceData.text_shot} target={"_blank"} style={{ color: 'white' }}>{sourceData.pdf_name.length > 90 ? sourceData.pdf_name.slice(0, 20) + "..." : sourceData.pdf_name}</a>}
            {sourceData.pdf_name.endsWith(".pdf") && <iframe src={`${serverUrl}/processed/` + sourceData.pdf_name + "#page=" + sourceData.page_num} title={sourceData.pdf_name} style={{ borderStyle: 'none', width: '73%', height: '400px' }}></iframe>}
            {sourceData.pdf_name.endsWith(".html") && <iframe src={`${serverUrl}/processed/` + sourceData.pdf_name + "#scroll-pos"} title={sourceData.pdf_name} style={{ borderStyle: 'none', width: '73%', height: '400px' }}></iframe>}
          </div >
        </>
      ))
    )}
  </Carousel >
)

const ExtraSourcesModal = ({ sourceDatas, language, serverUrl, setIsOpen, modalIsOpen, responsive }) => (
  <Modal
    isOpen={modalIsOpen}
    onRequestClose={() => setIsOpen(false)}
    style={
      {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'black',
          borderRadius: '20px',
          border: '1px solid #ccc',
          opacity: 0.95,
          color: 'white',
          width: '80%',
          zIndex: 9999
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }
      }
    }
    contentLabel="Example Modal"
  >
    <Carousel responsive={responsive}>
      {sourceDatas.map((sourceData, index) => (
        <>
          <h3 style={{ textAlign: 'center' }}>{sourceData.extra}</h3>
          <div style={{ display: 'flex', flexDirection: 'row', }}>
            <div style={{ maxHeight: '400px', padding: '2rem 9rem', margin: '1rem 0rem', overflow: 'hidden', width: '70%' }}>
              <div style={{ height: '100%', overflowY: 'auto', paddingRight: '1rem', boxSizing: 'border-box' }}>
                <div style={{ textAlign: 'justify', fontFamily: 'Poppins', lineHeight: '2rem' }}>
                  <p style={{ margin: 0, fontSize: '1.2rem', }}>{sourceData.context}</p>
                </div>
                <div style={{ textAlign: 'justify', fontFamily: 'Poppins', lineHeight: '2rem' }}>
                  <br></br>
                  <strong style={{ fontSize: '1.3rem' }}> Parsed Text:</strong>
                  <pre style={{ margin: 0, fontSize: '1.2rem', }}>{sourceData.parsedText}</pre>
                </div>
              </div>
            </div>
            {sourceDatas.length > 0 && (
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '2rem 0rem' }}>
                {sourceData.pdf_name.startsWith("http") && <a href={sourceData.pdf_name + "#:~:text=" + sourceData.text_shot} target={"_blank"} style={{ color: 'white' }}>{sourceData.pdf_name.length > 90 ? sourceData.pdf_name.slice(0, 20) + "..." : sourceData.pdf_name}</a>}
                {sourceData.pdf_name.endsWith(".pdf") && <iframe src={`${serverUrl}/processed/` + sourceData.pdf_name + "#page=" + sourceData.page_num} title={sourceData.pdf_name} style={{ borderStyle: 'none', width: '600px', minHeight: '400px' }}></iframe>}
                {sourceData.pdf_name.endsWith(".html") && <iframe src={`${serverUrl}/processed/` + sourceData.pdf_name + "#scroll-pos"} title={sourceData.pdf_name} style={{ borderStyle: 'none', width: '600px', minHeight: '400px' }}></iframe>}
              </div >)}
          </div>
        </>
      ))}
    </Carousel>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <button
        style={{ borderRadius: '30px', backgroundColor: '#333', marginBottom: '1.3rem', }}
        onClick={() => setIsOpen(false)}>
        {(language == "English") ? "Close" : "اغلق"}
      </button>
    </div>
  </Modal>
);


function FeedbackForm({ message, previousMessage, language, showFeedback, rating, setRating, comment, setComment, submitted, setSubmitted, firstIfEnglish, serverUrl }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
      <p style={{ marginRight: '10px' }}>
        {firstIfEnglish("Rating", "تقييم")}
      </p>
      <Rating
        style={{ maxWidth: 100, marginRight: '10px' }}
        value={rating}
        onChange={setRating}
        itemStyles={{
          itemShapes: RoundedStar,
          activeFillColor: '#ffb700',
          inactiveFillColor: '#3d3d3d'
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
        <input
          type="text"
          placeholder={firstIfEnglish("Comment", "تعليق")}
          value={comment}
          onChange={e => setComment(e.target.value)}
          style={{
            width: 400,
            borderRadius: '30px',
            borderStyle: 'none',
            backgroundColor: '#222',
            color: 'white',
            marginRight: '10px',
            padding: '0.5rem',
            outline: 'none'
          }}
        />
        <button
          onClick={() => {
            const feedback = {
              userMessage: previousMessage.text,
              botMessage: message.text,
              sourceData: message.sourceData,
              rating: rating,
              comment: comment,
            };

            fetch(`${serverUrl}/send_feedback/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(feedback),
            })
              .then(response => response.json())
              .then(data => {
                console.log('Success:', data);
                setSubmitted(true);
              })
              .catch(error => {
                console.error('Error:', error);
              });

          }}
          style={{
            borderRadius: '30px',
            backgroundColor: '#333',
            color: 'white',
            padding: '0.5rem 1rem',
            height: 'fit-content',
            marginTop: 0,
            marginLeft: '10px',
            marginRight: '10px'
          }}
        >
          {firstIfEnglish("Submit", "إرسال")}
        </button>
      </div>
    </div>
  );
}

export default ChatMessage;
import React, { useState, useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';
import { Dictaphone } from './Extras/Dictaphone';
import { BsFillSendFill } from "react-icons/bs";
import { MdFileUpload } from "react-icons/md";
import { FaStar } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import { handleFileChange, handleSendMessage } from '../requests';
import { IoIosSettings } from "react-icons/io";


function ChatInterface() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const messagesEndRef = useRef(null);

  const [model, setModel] = useState('GPT');
  const [language, setLanguage] = useState('Arabic');

  const [msgLoading, setMsgLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [showFeedback, setShowFeedback] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const [uploadLabel, setUploadLabel] = useState("Upload Knowledge");
  const fileInputRef = useRef(null);
  const formRef = useRef(null);

  return (
    <div className="chat-container">
      {/* Initial Background */}
      {messages.length === 0 && <InitialBackground />}

      {/* Chatbot Header */}
      <ChatbotHeader />

      {/* Chatbox with messages */}
      <div className="chat-messages">
        {messages.map((message, index) => (
          <ChatMessage key={index} message={message} previousMessage={messages[index - 1]} language={language} showFeedback={showFeedback} />
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Chatbox input */}
      <ChatboxInput setInputText={setInputText} inputText={inputText} messages={messages}
        setMessages={setMessages} setMsgLoading={setMsgLoading} model={model} language={language}
        uploadLabel={uploadLabel}
        setUploadLabel={setUploadLabel}
        uploadLoading={uploadLoading}
        setUploadLoading={setUploadLoading}
        fileInputRef={fileInputRef}
        formRef={formRef}
        showSettings={showSettings}
        setShowSettings={setShowSettings}
      />

      {/* Bottom bar */}
      {showSettings && <BottomBar
        uploadLabel={uploadLabel}
        setUploadLabel={setUploadLabel}
        uploadLoading={uploadLoading}
        setUploadLoading={setUploadLoading}
        formRef={formRef}
        fileInputRef={fileInputRef}
        model={model}
        setModel={setModel}
        language={language}
        setLanguage={setLanguage}
        showFeedback={showFeedback}
        setShowFeedback={setShowFeedback}
      />}

    </div>
  );
}


function InitialBackground() {

  return (
    <>
      {true && (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
          }}
        >
          <img
            src="./logo-hyper.png"
            alt="ChatBot"
            style={{
              width: "80%",
              maxWidth: "900px",
              marginBottom: "0px",
              opacity: "0.45",
            }}
          />
        </div>
      )}
    </>
  );
}

function ChatbotHeader() {
  return (
    <div style={{ backgroundColor: "rgba(21, 21, 21, 0.975)", padding: '10px 15px' }}>
      <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
        <div style={{ position: 'relative' }}>
          <img src="llama.png" alt="ChatBot" className="user_img" />
          <span className="online_icon"></span>
        </div>
        <div className="user_info" style={{ color: 'white', fontFamily: 'Poppins', fontSize: '1.7rem', paddingLeft: '10px' }}>
          <span>HyperGPT</span>
          <p style={{ fontSize: '1.0rem', color: '#cccccc77', marginTop: '0rem', fontFamily: 'Poppins', fontWeight: '500' }}>Ask me anything!</p>
        </div>
      </div>
    </div>
  );
}

function ChatboxInput({ setInputText, inputText, messages, setMessages, setMsgLoading, model, language,
  uploadLabel,
  setUploadLabel,
  uploadLoading,
  setUploadLoading,
  fileInputRef,
  formRef,
  showSettings,
  setShowSettings
}) {
  const override = {
    margin: "0rem 0rem 0rem 1rem",
  };
  return (
    <div style={{ width: '100%', display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
      <form id="messageArea" style={{ flexBasis: '100%', display: 'flex', backgroundColor: 'transparent', flexDirection: 'row', justifyContent: 'space-around', }} onSubmit={(e) => handleSendMessage(e, setInputText, inputText, messages, setMessages, setMsgLoading, model, language)}
      >
        <input
          id="text"
          name="msg"
          style={{ width: '99%', padding: '10px', paddingRight: '7rem', paddingLeft: '9rem', backgroundColor: "rgba(21, 21, 21, 1.0)", fontSize: '1.3rem', height: '3rem', textAlign: (language === 'English') ? 'left' : 'right', borderRadius: '14px', outline: 'none', border: 'none', color: 'white', position: 'relative' }}
          placeholder={(language === 'English') ? "What is your question..." : "...ما هو سؤالك"}
          autoComplete="off"
          className="form-control type_msg"
          required
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
        />
        <div style={{ color: 'white', fontSize: '1.4rem', position: 'absolute', right: '4rem', cursor: 'pointer', zIndex: '199', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '0.8rem' }}>
          <Dictaphone text={inputText} setText={setInputText} />
          <BsFillSendFill onClick={(e) => handleSendMessage(e, setInputText, inputText, messages, setMessages, setMsgLoading, model, language)} />
        </div>
        <div style={{ color: 'white', fontSize: '1.4rem', position: 'absolute', left: '4rem', cursor: 'pointer', zIndex: '199', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '0.8rem' }}>
          <IoIosSettings onClick={() => setShowSettings(!showSettings)} style={{ fontSize: '1.7rem' }} />
          <form
            ref={formRef}
            className="input-group mt-2"
            encType="multipart/form-data"
            method="POST"
            style={{ backgroundColor: 'transparent', padding: '20px 0px' }}
          >          <label htmlFor="files" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '0.8rem' }}>
              {!uploadLoading && <MdFileUpload style={{ fontSize: '1.7rem' }} />}
              {uploadLoading && <ClipLoader cssOverride={override} color={'#ccc'} loading={true} size={20} />}
            </label>
            <input
              style={{ display: 'none' }}
              type="file"
              id="files"
              name="files"
              className="form-control-file"
              multiple
              ref={fileInputRef}
              onChange={(event) => handleFileChange(event, setUploadLabel, setUploadLoading, formRef, fileInputRef)}
            />
          </form>
        </div>
      </form>
    </div>
  )
}

const BottomBar = ({
  uploadLabel,
  setUploadLabel,
  uploadLoading,
  setUploadLoading,
  formRef,
  fileInputRef,
  model,
  setModel,
  language,
  setLanguage,
  showFeedback,
  setShowFeedback,
}) => {
  const override = {
    margin: "0rem 0rem 0rem 1rem",
  };
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-1rem', padding: '1rem 4rem', backgroundColor: 'transparent', alignItems: 'center', flexWrap: 'wrap' }}>

      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1rem' }}>
        <select
          style={{ backgroundColor: 'transparent', color: '#ccc', borderColor: '#ccc', minWidth: '25vw', borderRadius: '5px', padding: '0rem 1rem', height: '3rem' }}
          id="modelSelect"
          className="form-control mt-2"
          onChange={(e) => { setModel(e.target.value); }}
        >
          <option value="GPT">GPT</option>
          <option value="Gemini">Gemini</option>
          <option value="Llama">Llama</option>
        </select>
        <select
          style={{ backgroundColor: 'transparent', color: '#ccc', borderColor: '#ccc', minWidth: 'max(15vw, 150px)', borderRadius: '5px', padding: '0rem 1rem', height: '3rem' }}
          className="form-control mt-2"
          onChange={(e) => { setLanguage(e.target.value); }}
        >
          <option value="Arabic">Arabic</option>
          <option value="English">English</option>
        </select>
        <div>
          <FaStar style={{ color: (!showFeedback) ? '#3d3d3d' : 'white', fontSize: '1.7rem', cursor: 'pointer' }} onClick={() => setShowFeedback(!showFeedback)} />
        </div>
      </div>

    </div>
  )
}

export default ChatInterface;